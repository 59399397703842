<template lang="pug">
BaseGroup(
	v-for="(ship, id) in map"
	:key="id"
)
	.ship
		div
			p(
				v-for="(name, key) in ship"
				:key="key"
			)
				b {{ key }}:{{' '}}
				span {{ name }}
		.flex.flex--fdc(v-if="isAdmin")
			BaseButton.mb-2(@click="$emit('show', id)") Подробнее
			BaseButton(view="danger" @click="$emit('delete', id)") Удалить
</template>
<script>
import BaseButton from '../ui/BaseButton'
import BaseGroup from '../ui/BaseGroup'
export default {
	name: 'ListShips',
	components: { BaseGroup, BaseButton },
	props: {
		map: {
			type: Object,
			required: true
		},
		isAdmin: {
			type: Boolean,
			required: true
		}
	},
	emits: ['show', 'delete']
}
</script>

<style lang="scss">
.ship {
	@include until-mobile {
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 66vw;
			display: block;
		}
	}
	@include until-desktop {
		display: grid;
		grid-gap: 8px;
	}
	@include for-desktop {
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			width: 300px;
		}
	}
}
</style>
